import React, { useState, useEffect } from "react"
import throttle from 'lodash/throttle'

const ReadingTime = ({ className, time, target }) => {
  const [readingProgress, setReadingProgress] = useState(0)
  const scrollListener = () => {
    if (!target.current) {
      return
    }

    const element = target.current
    const totalHeight = element.clientHeight - element.offsetTop
    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (windowScrollTop === 0) {
      return setReadingProgress(0)
    }

    if (windowScrollTop > totalHeight) {
      return setReadingProgress(100)
    }

    // console.log(windowScrollTop)

    setReadingProgress((windowScrollTop / totalHeight) * 100)
  }
  useEffect(() => {
    const throttled_version = throttle(scrollListener, 30000)
    window.addEventListener("scroll", throttled_version)
    return () => throttled_version.cancel()
    // window.removeEventListener("scroll", scrollListener)
  })

  return (
    <div className={`${className}`}>
      <div
        className={`text-center text-mono text-sm`}
        style={{
          position: "sticky",
          top: 44,
          width: "100%",
        }}
      >
        <p>
          {time} min read {Math.round(readingProgress)}%
        </p>
      </div>
    </div>
  )
}

export default ReadingTime
