import React, { useMemo } from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../layouts"
import SEO from "../components/seo"
import ReadingTime from "../components/readingtime"
import { GatsbyImage } from "gatsby-plugin-image";
// import { rhythm, scale } from "../utils/typography"
import "./prism-okaidia.css"
import Teaser from "../components/teaser"
import { motion } from "framer-motion"

const BlogPostTemplate = (props) => {
  const target = React.createRef()

  const post = props.data.post
  const nextpost = props.data.nextpost
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext
  const gravatar = props.data.gravatar
  
  // <Layout location={props.location} title={siteTitle}>
  return <>
    <SEO
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
    <article className="col-span-8 mt-40 sm:mt-0">
      <header className="relative z-20 grid grid-cols-8 gap-8">
        <h1 className="col-span-8 sm:col-span-5 sm:col-start-2 -mt-1 font-mono leading-none text-5xl md:text-6xl lg:text-8xl hyphens-auto">
          {post.frontmatter.title}
        </h1>
        <div className="flex flex-col justify-end col-span-4 sm:col-span-2 text-sm leading-loose sm:text-center font-regular">
          <div className="">
            <div className="hidden sm:block w-20 h-20 m-auto">
              <img
                className="rounded-full filter-grayscale"
                src={gravatar.url}
              />
            </div>
            <span>by Steve Sinnwell</span>
            {/* {date} &mdash;  post.frontmatter.date */}
          </div>
        </div>
      </header>
      <section className="grid grid-cols-8 gap-8">
        <div className="relative z-10 col-span-8 sm:col-span-3">
          <motion.div magic magicid="image">
            <GatsbyImage
              image={post.frontmatter.cover.childImageSharp.gatsbyImageData}
              className="mt-10 sm:-mt-40 aspect-w-16 aspect-h-9"
              alt={post.frontmatter.title}
            />
          </motion.div>
        </div>
      </section>
      <section className="relative z-20 grid grid-cols-8 gap-8">
        <div
          ref={target}
          className="col-span-8 sm:col-span-5 sm:col-start-2 font-mono text-xl markdown"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <ReadingTime
          className="hidden sm:block col-span-2 pt-16"
          time={post.timeToRead}
          target={target}
        />
      </section>
    </article>

    <div>
      <Teaser post={nextpost} index={1} />
    </div>

    {/*
      <nav>
        <ul
          className="flex flex-wrap justify-between mb-8"
        >
          <li>
            {previous && (
              <Link
                className="text-blue-600"
                to={previous.fields.slug}
                rel="prev"
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                className="text-blue-600"
                to={next.fields.slug}
                rel="next"
              >
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      */}
  </>;
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostBySlug($slug: String!) {
  site {
    siteMetadata {
      title
    }
  }
  gravatar(email: {eq: "steve@kopfwelt.com"}) {
    url
  }
  post: markdownRemark(fields: {slug: {eq: $slug}}) {
    id
    excerpt(pruneLength: 160)
    timeToRead
    html
    frontmatter {
      title
      subtitle
      cover {
        childImageSharp {
          gatsbyImageData(height: 500, quality: 90, layout: FULL_WIDTH)
        }
      }
      date(formatString: "MMMM DD, YYYY")
      description
    }
  }
  nextpost: markdownRemark(fields: {slug: {eq: $slug}}) {
    ...TeaserFragment
  }
}
`
